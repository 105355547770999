import React, {useState} from 'react';
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/logo.svg'
import './navbar.css';

const Menu = () => ( // Using {} is necessary when you want to include multiple statements or logic before returning a value. With parenthesis, the value inside is returned automatically without needing to explicitly use the return function.
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#wgpt3">What is GPT3?</a></p>
  <p><a href="#possibility">Open AI</a></p>
  <p><a href="#features">Case Studies</a></p>
  <p><a href="#blog">Library</a></p>
  </>
)


// BEM » Block Emenet Modifier

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false); // Creating a conditional toggle menu
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
           <img src={logo} alt="logo"/>
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <p>Sign in</p>
        <button className="pulsate-fwd sign-up-btn" type="button">Sign up</button>
      </div>
      <div className="gpt3__navbar-menu"> 
      {toggleMenu 
        ? <RiCloseLine color="#fff" size={27} onClick={()=> setToggleMenu(false)} />
        : <RiMenu3Line color="#fff" size={27} onClick={()=> setToggleMenu(true)} />
      }
      {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <Menu />
            <div className="gpt3__navbar-menu_container-links-sign">
               <p>Sign in</p>
                  <button type="button">Sign up</button>
             </div>
          </div>
        </div>
      )}
      </div>
    </div>
  )
}

export default Navbar