import React from 'react';
import './header.css';
import people from '../../assets/people.svg';
import ai from '../../assets/ai.svg';

const Header = () => {
  return (
    <div className="gpt3__header section__padding " id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text ">Let’s Build Something Amazing with GPT-3</h1>
        <p>Empower your projects with GPT-3’s unmatched AI capabilities. Whether you’re crafting content or developing innovative apps, GPT-3 is your key to endless possibilities.</p>
      
        <div className="gpt3__header-content__input">
          <input 
          type="email" 
          placeholder="Your Email Address" 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (e.target.value.length >= 5) {
              alert("Please check your email for the next steps!");
            } else {
                alert("Please enter a valid email.");
              }
            }
          }}
          />
             
             <button 
             type="button" onClick={(e) => { alert("You are good to go!")}}>Get Started</button>

        </div>

        <div className="gpt3__header-content__people">
          <img src={people} alt="people" />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div>

      </div>
      <div className="gpt3__header-image">
          <img src={ai} alt="ai" />
        </div>
      </div>
  )
}

export default Header